import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {
    File,
    Hub,
    HubGate,
    Ibox,
    Order,
    OrderBarCode,
    OrderGood,
    OrderOption,
    OrderSignature,
    OrderZone,
    PpAct,
    ReturnAct,
    ServiceRequest,
    ShippingQueue,
    User, Zorder,
} from './models';
import {OrdersService} from './orders.service';
import {CurrentUserService} from './current-user.service';
import {AgentReport, AgentReportBankPaymentOrderPart, BankPaymentOrder} from '../finance/finance-models';

@Injectable({
    providedIn: 'root',
})
export class HelpersService {
    static zones: OrderZone[] = [];
    private expenseTypes = [
        'Неизвестный тип',
        'Парковка',
        'Другое',
        'Парковка в БЦ',
        'Точки',
    ];

    private expenseStatuses = [
        'Запрос',
        'Одобрен',
        'Отменен',
    ]

    public inventivActsStatuses = [
        {'id': 1, 'name': 'Новый'},
        {'id': 2, 'name': 'Обработан'},
    ];

    public TERMINAL_TYPES = [
        {'id': 1, 'name': 'Ibox'},
        {'id': 2, 'name': 'Assist'},
    ];

    public DOCUMENT_RECEIVE_STATUSES = [
        {'id': 1, 'name': 'получены курьером'},
        {'id': 2, 'name': 'не было в заказе'},
        {'id': 3, 'name': 'покупатель не отдал'},
    ];

    public QCCompleteNames = ['0 - отлично',
        '1 - Нет региона',
        '2 - Нет города',
        '3 - Нет улицы',
        '4 - Нет дома',
        '5 - Нет квартиры',
        '6 - Адрес неполный',
        '7 - иностранный адрес',
        '8 - OK',
        '9 - Нужна проверка разбора',
        '10 - Под вопросом, дома нет в фиас'];


    public QCNames = ['0 - Адрес распознан уверенно',
        '1 - остались «лишние» части',
        '2 - Адрес «мусорный»',
        '3 - есть альтернативы'];

    constructor(
        private api: OrdersService,
        protected currentUser: CurrentUserService,
    ) {
    }


    public terminalTypeName(typeId) {
        const itemS = this.TERMINAL_TYPES.find(item => item.id === typeId);
        if (!itemS) {
            return 'Неизвестный тип';
        }
        return itemS.name;
    }


    public findNameById(id, dictionary) {
        const itemS = dictionary.find(item => item.id === id);
        if (!itemS) {
            return 'Неизвестный тип';
        }
        return itemS.name;
    }

    public documentReceiveStatusDescription(status) {
        const itemS = this.DOCUMENT_RECEIVE_STATUSES.find(item => item.id === status);
        if (!itemS) {
            return 'Неизвестный статус';
        }
        return itemS.name;
    }

    public pretensionStatusDescription(status) {
        return this.findNameById(status, OrderOption.PRETENSION_STATUSES_DESCRIPTIONS)
    }


    public barcodeStatusDescription(status) {
        const itemS = OrderBarCode.STATUSES_DESCRIPTION.find(item => item.id === status);
        if (!itemS) {
            return 'Неизвестный статус';
        }
        return itemS.description;
    }


    public isYandex(clientId) {
        return (clientId === environment.yandex_client_id);
    }

    public isDetmir(clientId) {
        return (clientId === environment.detmir_client_id);
    }

    public isScalar(value: any): boolean {
        return (/boolean|number|string/).test(typeof value);
    }

    public expenseTypeName(type) {
        return this.expenseTypes[type];
    }

    public expenseStatusName(status) {
        return this.expenseStatuses[status];
    }

    /**
     * Хешированный список зон
     */
    public getZones(zone_type = 1) {
        return new Observable((observer) => {
            if (!HelpersService.zones.length) {
                this.api.getZones(null, null, zone_type).subscribe(zones => {
                    HelpersService.zones[zone_type] = zones;
                    observer.next(HelpersService.zones[zone_type]);
                    observer.complete();
                });
            } else {
                observer.next(HelpersService.zones[zone_type]);
                observer.complete();
            }
        });
    }

    /**
     * Вспомогательный метод возращающий
     * цвет статуса забора по id статусу
     * @param idStatus
     */
    public getZorderStatusColor(idStatus) {
        const colors = [
            'gray', // новый
            'primary', // подтверждённый
            'success', // выполнен
            'danger', // отмена
            'success', // Выполняется
            'info', // перенос
            'teal', // На складе
            'orange', // Отказ на месте
        ];
        if (!colors[idStatus - 1]) {
            return 'primary';
        }
        return colors[idStatus - 1];
    }

    /**
     * Название статуса заказа
     * @param status
     */
    public getNameOrderStatus(status: any) {
        let statusInt = status;
        if (status instanceof Order) {
            statusInt = status.status;
        }

        const statuses = [
            'Новая заявка',
            'Заявка принята',
            'На складе',
            'На доставке',
            'Доставлен',
            'Частичный отказ',
            'Полный отказ',
            'Отмена',
            'В перемещении',
            'Отмена для возврата',
        ];
        if (!statuses[statusInt - 1]) {
            return 'неизвестный статус';
        }
        return statuses[statusInt - 1];
    }

    public getQCGeoName(status) {
        const statuses = ['0 — точные координаты',
            '1 — ближайший дом',
            '2 — улица',
            '3 — населенный пункт',
            '4 — город',
            '5 — координаты не определены'];
        return statuses[status];
    }

    public getQCCompleteName(status) {

        return this.QCCompleteNames[status];
    }

    public getQCName(status) {
        return this.QCNames[status];
    }

    public getNameGoodStatus(good: OrderGood) {
        const status = OrderGood.STATUSES_DESCRIPTIONS.find(goodStatus => goodStatus.id === good.status);

        if (!status) {
            return 'неизвестный статус';
        }
        return status.name;
    }

    public getNamePlaceStatus(status) {
        const statuses = [
            'Ожидается на склад',
            'Принят на склад',
            'Выдан на доставку',
            'В перемещении',
        ];

        if (!statuses[status - 1]) {
            return 'неизвестный статус';
        }
        return statuses[status - 1];

    }

    public getNameTEStatus(status) {
        const statuses = [
            'Новая',
            'Собрана',
            'На доставке',
            'Доставлена',
        ];
        if (!statuses[status - 1]) {
            // throw new Error('Invalid status');
            return 'неизвестный статус';
        }
        return statuses[status - 1];
    }


    public getNameTransitStatus(status: number) {
        const statuses = [
            'Новое',
            'На доставке',
            'Доставлено',
        ];

        if (!statuses[status - 1]) {
            // throw new Error('Invalid status');
            return 'неизвестный статус';
        }
        return statuses[status - 1];
    }

    /**
     * Название статуса забора
     * @param num
     */
    public getNameZOrderStatus(num) {
        const statuses = [
            'Новый',
            'Подтвержден',
            'Выполнен',
            'Отмена',
            'Выполняется',
            'Перенос',
            'На складе',
            'Отказ на месте',
        ];

        if (!statuses[num - 1]) {
            return 'неизвестный статус ' + num;
        }

        return statuses[num - 1];
    }

    /**
     * Разрешает значение статуса курьера в очереди и возвращает подходящий объект описания этого статуса
     * @param courierStatus
     * @private
     */
    private _resolveShippingQueueCourierStatus(courierStatus: any) {
        if (courierStatus === null || courierStatus.queue_status === null) {
            courierStatus = 0;
        }

        if ('undefined' !== typeof courierStatus.queue_status) {
            courierStatus = courierStatus.queue_status;
        }

        if ('undefined' === typeof ShippingQueue.COURIER_STATUSES[courierStatus]) {
            return null;
        }

        return ShippingQueue.COURIER_STATUSES[courierStatus];
    }

    /**
     * Название статуса курьера в очереди
     * @param courierStatus
     * @param voidOnEmpty
     */
    public getShippingQueueCourierStatusName(courierStatus: any, voidOnEmpty = false): string | void {
        const status = this._resolveShippingQueueCourierStatus(courierStatus);
        if (status) {
            return status.name;
        }

        if (voidOnEmpty) {
            return;
        }

        return 'неизвестный статус';
    }

    /**
     * Классы бейджика статуса курьера в очереди
     * @param courierStatus
     * @param voidOnEmpty
     */
    public getShippingQueueCourierStatusClasses(courierStatus: any, voidOnEmpty = false): string | void {
        const status = this._resolveShippingQueueCourierStatus(courierStatus);
        if (status) {
            return status.classes;
        }

        if (voidOnEmpty) {
            return;
        }

        return 'border border-danger text-danger';
    }

    /**
     * Разрешает значение статуса ворот и возвращает подходящий объект описания этого статуса
     * @param hubGateStatus
     * @private
     */
    private _resolveHubGateStatus(hubGateStatus: any) {
        if (hubGateStatus === null || hubGateStatus.status === null) {
            hubGateStatus = 0;
        }

        if ('undefined' !== typeof hubGateStatus.status) {
            hubGateStatus = hubGateStatus.status;
        }

        hubGateStatus--;

        if ('undefined' === typeof HubGate.STATUSES[hubGateStatus]) {
            return null;
        }

        return HubGate.STATUSES[hubGateStatus];
    }

    /**
     * Название статуса ворот
     * @param hubGateStatus
     * @param voidOnEmpty
     */
    public getHubGateStatusName(hubGateStatus: any, voidOnEmpty = false): string | void {
        const status = this._resolveHubGateStatus(hubGateStatus);
        if (status) {
            return status.name;
        }

        if (voidOnEmpty) {
            return;
        }

        return 'неизвестный статус';
    }

    /**
     * Классы бейджика статуса ворот
     * @param hubGateStatus
     * @param voidOnEmpty
     */
    public getHubGateStatusClasses(hubGateStatus: any, voidOnEmpty = false): string | void {
        const status = this._resolveHubGateStatus(hubGateStatus);
        if (status) {
            return status.classes;
        }

        if (voidOnEmpty) {
            return;
        }

        return 'border border-danger text-danger';
    }

    /**
     * Разрешает значение статуса заявки на сервисные услуги и возвращает подходящий объект описания этого статуса
     * @param ServiceRequestStatus
     * @private
     */
    private _resolveServiceRequestStatus(ServiceRequestStatus: any) {
        if (ServiceRequestStatus === null || ServiceRequestStatus.status === null) {
            ServiceRequestStatus = 0;
        }

        if ('undefined' !== typeof ServiceRequestStatus.status) {
            ServiceRequestStatus = ServiceRequestStatus.status;
        }

        ServiceRequestStatus--;

        if ('undefined' === typeof ServiceRequest.STATUSES[ServiceRequestStatus]) {
            return null;
        }

        return ServiceRequest.STATUSES[ServiceRequestStatus];
    }

    /**
     * Название статуса заявки на сервисные услуги
     * @param ServiceRequestStatus
     * @param voidOnEmpty
     */
    public getServiceRequestStatusName(ServiceRequestStatus: any, voidOnEmpty = false): string | void {
        const status = this._resolveServiceRequestStatus(ServiceRequestStatus);
        if (status) {
            return status.name;
        }

        if (voidOnEmpty) {
            return;
        }

        return 'неизвестный статус';
    }

    /**
     * Классы бейджика статуса заявки на сервисные услуги
     * @param ServiceRequestStatus
     * @param voidOnEmpty
     */
    public getServiceRequestStatusClasses(ServiceRequestStatus: any, voidOnEmpty = false): string | void {
        const status = this._resolveServiceRequestStatus(ServiceRequestStatus);
        if (status) {
            return status.classes;
        }

        if (voidOnEmpty) {
            return;
        }

        return 'border border-danger text-danger';
    }

    /**
     * Название типа оплат заказов в АО
     * @param codTypeId
     * @param voidOnEmpty
     */
    public getCodTypesName(codTypeId: any, voidOnEmpty = false): string | void {
        const codType = this._resolveCodTypes(codTypeId);
        if (codType) {
            return codType.name;
        }

        if (voidOnEmpty) {
            return;
        }

        return 'неизвестный тип';
    }

    /**
     * Разрешает значение типа оплат заказов в АО и возвращает подходящий объект описания этого типа
     * @param codTypeId
     * @private
     */
    private _resolveCodTypes(codTypeId: any) {
        if (codTypeId === null || codTypeId.id === null) {
            codTypeId = 0;
        }

        if ('undefined' !== typeof codTypeId.id) {
            codTypeId = codTypeId.id;
        }

        codTypeId--;

        if ('undefined' === typeof BankPaymentOrder.COD[codTypeId]) {
            return null;
        }

        return BankPaymentOrder.COD[codTypeId];
    }

    /**
     * Название статуса платёжного поручения
     * @param bankPaymentOrderStatus
     * @param voidOnEmpty
     */
    public getBankPaymentOrderStatusName(bankPaymentOrderStatus: any, voidOnEmpty = false): string | void {
        const status = this._resolveBankPaymentOrderStatus(bankPaymentOrderStatus);
        if (status) {
            return status.name;
        }

        if (voidOnEmpty) {
            return;
        }

        return 'неизвестный статус';
    }

    /**
     * Разрешает значение статуса платёжного поручения
     * @param bankPaymentOrderStatus
     * @private
     */
    private _resolveBankPaymentOrderStatus(bankPaymentOrderStatus: any) {
        if (bankPaymentOrderStatus === null || bankPaymentOrderStatus.status === null) {
            bankPaymentOrderStatus = 0;
        }

        if ('undefined' !== typeof bankPaymentOrderStatus.id) {
            bankPaymentOrderStatus = bankPaymentOrderStatus.id;
        }

        bankPaymentOrderStatus--;

        if ('undefined' === typeof BankPaymentOrder.STATUSES[bankPaymentOrderStatus]) {
            return null;
        }

        return BankPaymentOrder.STATUSES[bankPaymentOrderStatus];
    }

    /**
     * Название статуса разнесения платёжного поручения к агентскому отчёту
     * @param agentReportBankPaymentOrderPartStatus
     * @param voidOnEmpty
     */
    public getAgentReportBankPaymentOrderPartStatusName(agentReportBankPaymentOrderPartStatus: any, voidOnEmpty = false): string | void {
        const status = this._resolveAgentReportBankPaymentOrderPartStatus(agentReportBankPaymentOrderPartStatus);
        if (status) {
            return status.name;
        }

        if (voidOnEmpty) {
            return;
        }

        return 'неизвестный статус';
    }

    /**
     * Разрешает значение статуса разнесения платёжного поручения к агентскому отчёту
     * @param agentReportBankPaymentOrderPartStatus
     * @private
     */
    private _resolveAgentReportBankPaymentOrderPartStatus(agentReportBankPaymentOrderPartStatus: any) {
        if (agentReportBankPaymentOrderPartStatus === null || agentReportBankPaymentOrderPartStatus.status === null) {
            agentReportBankPaymentOrderPartStatus = 0;
        }

        if ('undefined' !== typeof agentReportBankPaymentOrderPartStatus.id) {
            agentReportBankPaymentOrderPartStatus = agentReportBankPaymentOrderPartStatus.id;
        }

        agentReportBankPaymentOrderPartStatus--;

        if ('undefined' === typeof AgentReportBankPaymentOrderPart.STATUSES[agentReportBankPaymentOrderPartStatus]) {
            return null;
        }

        return AgentReportBankPaymentOrderPart.STATUSES[agentReportBankPaymentOrderPartStatus];
    }

    public getCourierType(type: number) {
        const statuses = [
            'Грузовой',
            'Легковой',
            'Пятитонник',
            'Стажер',
            'Наёмник',
        ];
        if (!statuses[type - 1]) {
            // throw new Error('Invalid status');
            return 'неизвестный тип';
        }
        return statuses[type - 1];
    }

    public getReturnActStatusName(statusId: number) {
        const status = ReturnAct.STATUSES_DESCRIPTION.filter(x => x.id === statusId)
        if (status.length === 0) {
            return 'неизвестный статус';
        }
        return status[0].name;
    }


    /**
     * Вывести месяц
     * @param month
     */
    public getMonthName(date) {
        const month = new Date(date).getMonth();
        const monthes = [
            'января', 'февраля', 'марта',
            'апреля', 'мая', 'июня', 'июля',
            'августа', 'сентября', 'октября',
            'ноября', 'декабря',
        ];
        if (!monthes[month]) {
            // throw new Error('Invalid month');
            return 'неизвестный месяц';
        }
        return monthes[month];
    }

    /**
     * Период доставки
     * @param interval
     */
    public getDeliveryTimePeriod(interval: number, deliveryTimeStart: number = 0, deliveryTimeEnd: number = 0) {
        switch (interval) {
            case 1: {
                return '10:00 - 14:00';
            }
            case 2: {
                return '10:00 - 18:00';
            }
            case 3: {
                return '14:00 - 18:00';
            }
            case 4: {
                return '18:00 - 22:00';
            }
            case 5: {
                return '10:00 - 22:00';
            }
            case 11: {
                return '12:00 - 18:00';
            }
            case 12: {
                return '18:00 - 22:00';
            }
            case 13: {
                return '12:00 - 22:00';
            }
            default: {
                if (!deliveryTimeStart || !deliveryTimeEnd) {
                    return 'Не передали интервал';
                } else {
                    return ((deliveryTimeStart < 10) ? '0' : '') + deliveryTimeStart + ':00 - ' + deliveryTimeEnd + ':00';
                }
            }
        }
    }

    /**
     * Период доставки с объекта заказа
     * @param order
     * @param minimized
     */
    getDeliveryTimePeriodOrder(order, minimized = false) {
        let start;
        let end;
        if (order.delivery_time) {
            const time = order.delivery_time;
            if (time === 1) {
                start = 10;
                end = 14;
            } else if (time === 2) {
                start = 10;
                end = 18;
            } else if (time === 3) {
                start = 14;
                end = 18;

            } else if (time === 4) {
                start = 18;
                end = 22;

            } else if (time === 5) {
                start = 10;
                end = 22;

            } else if (time === 11) {
                start = 12;
                end = 18;
            } else if (time === 12) {
                start = 18;
                end = 22;
            } else if (time === 13) {
                start = 12;
                end = 22;
            }
        } else {
            start = order.delivery_time_start;
            end = order.delivery_time_end;
        }
        if (start && end) {
            if (minimized) {
                return start + '-' + end
            } else {
                return start + ':00 - ' + end + ':00'
            }
        } else {
            return '-';
        }
    }

    /**
     * Расшифровка опции вскрытия
     * @param option
     */
    public getOptionOpeningName(option: number) {
        switch (option) {
            case 1: {
                return 'Разрешено';
            }
            case 2: {
                return 'Только внешней упаковки заказа';
            }
            case 3: {
                return 'Запрещено';
            }
            default: {
                return 'Не указано';
            }
        }
    }

    /**
     * Список предстатусов для проблем
     */
    public getPredstatusList() {
        const predstatusList = [
            'Недозвон',
            'Сдвиг',
            'Перенос',
            'Отмена',
            'Подъём КГТ',
        ];
        return predstatusList;
    }

    /**
     * Получение предстатуса для проблем
     * @param predstatus
     */
    public getPredstatus(predstatus) {
        if (predstatus === '2-27') {
            return 'Сдвиг';
        }
        const predstatuses = [
            'Недозвон',
            'Перенос',
            'Отмена',
            'КГТ',
            'Отказ на месте',
            'Сдвиг интервала',
            'Покупателя нет на месте',
        ];
        if (predstatus == 7) {
            return 'Покупателя нет на месте';
        }
        if (predstatus == 99) {
            return 'КГТ';
        }
        if (!predstatuses[predstatus - 1]) {
            // throw new Error('Invalid status');
            return 'неизвестный статус';
        }
        return predstatuses[predstatus - 1];
    }

    public fileTypeName(type: number) {
        if (!File.TYPE_DESCRIPTION[type - 1]) {
            return 'неизвестный тип';
        }
        return File.TYPE_DESCRIPTION[type - 1];
    }

    /**
     * Расшифровка типа ндс
     * @param nds
     */
    public getNdsName(nds: number) {
        switch (nds) {
            case 1: {
                return 'HДС 18%';
            }
            case 2: {
                return 'Без HДС';
            }
            case 3: {
                return 'HДС 10%';
            }
            case 4: {
                return 'HДС 18/118';
            }
            case 5: {
                return 'HДС 10/110';
            }
            case 6: {
                return 'НДС 0%';
            }
            case 7: {
                return 'НДС 20%';
            }
            case 8: {
                return 'НДС 20/120';
            }
            default: {
                return '?';
            }
        }
    }

    /**
     * Права доступа для карточки заказа
     * @param name
     * @param user
     */
    public checkPermissions(name_permission) {
        let user: any = [];
        this.currentUser.get().subscribe(data => {
            user = data;
        })
        for (const user_permission of user.permissions) {
            if (name_permission === user_permission.name) {
                return true;
            }
        }
        return false;
    }

    /**
     * Проверяет наличие хотя бы одного права доступа из списка
     * @param permissions
     */
    public checkPermissionsAny(permissions: string[]) {
        let user: any = [];
        this.currentUser.get().subscribe(data => {
            user = data;
        })
        for (const user_permission of user.permissions) {
            if (permissions.includes(user_permission.name)) {
                return true;
            }
        }
        return false;
    }

    public viewerRules(name, user, order) {
        if (user.role === 1) {
            return true;
        }
        let operator = false;
        if (user.role === 9) {
            operator = true;
        }
        let ChiefOperator = false;
        /*for (const group of user.groups) {
            if (group.title === 'ChiefOperator') {
                ChiefOperator = true;
            }
        }*/
        switch (name) {
            case 'edit_order': {
                if (operator) {
                    return true;
                } else {
                    return false;
                }
                break;
            }
            case 'is_important': {
                if (operator && ChiefOperator) {
                    return true;
                } else {
                    return false;
                }
                break;
            }
            case 'courier': {
                if (order.status === 5 || order.status === 6 || order.status === 7 || order.status === 8) {
                    return false;
                } else {
                    if (operator || ChiefOperator) {
                        return true;
                    }
                }
                break;
            }
            case 'set_status' : {
                // TODO добавить установку конечного статуса в интерфейс
                break;
            }
            case 'edit_goods' : {
                if (order.status === 5 || order.status === 6 || order.status === 7 || order.status === 8) {
                    if (ChiefOperator) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    if (operator || ChiefOperator) {
                        return true;
                    }
                }
                break;
            }
            case 'edit_options' : {
                if (ChiefOperator) {
                    return true;
                } else {
                    return false;
                }
                break;
            }
            case 'address' : {
                if (operator) {
                    return true;
                } else {
                    return false;
                }
                break;
            }
            case 'weight' : {
                if (operator) {
                    return true;
                } else {
                    return false;
                }
                break;
            }
            case 'dimension' : {
                if (operator) {
                    return true;
                } else {
                    return false;
                }
                break;
            }
            case 'operator_comment' : {
                if (order.status === 5 || order.status === 6 || order.status === 7 || order.status === 8) {
                    return false;
                } else {
                    if (operator) {
                        return true;
                    } else {
                        return false
                    }
                }
                break;
            }
            case 'delivery_date' : {
                if (order.status === 5 || order.status === 6 || order.status === 7 || order.status === 8) {
                    return false;
                } else {
                    if (operator) {
                        return true;
                    } else {
                        return false
                    }
                }
                break;
            }
            case 'interval' : {
                if (order.status === 5 || order.status === 6 || order.status === 7 || order.status === 8) {
                    return false;
                } else {
                    if (operator) {
                        return true;
                    } else {
                        return false
                    }
                }
                break;
            }
            case 'drop_status' : {
                if (ChiefOperator) {
                    return true;
                } else {
                    return false;
                }
                break;
            }
            case 'inner_n' : {
                if (ChiefOperator) {
                    return true;
                } else {
                    return false;
                }
                break;
            }
            default : {
                break;
            }
        }
    }


    public formatDateForSQL(str) {
        return str.replace(/(\d{2})\.(\d{2})\.(\d{4})/, '$3-$2-$1');
    }

    public formatDate(str) {
        return str.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3.$2.$1');
    }

    public formatDateTime(str) {
        return str.replace(/(\d{4})-(\d{2})-(\d{2}) (\d{2}:\d{2}):(\d{2})/, '$3.$2.$1 $4');
    }

    /**
     routeLink на печать возвратного акта
     **/
    public getReturnActPrintRouteLink(returnActId, printPlaces = '', clientId) {
        return [
            '/print-return-acts',
            {
                'return_act_id': returnActId,
                'print_places': printPlaces,
                'client_id': clientId
            },
        ]
    }

    public getReturnActEditRouteLink(act) {
        return '/admin/sklad/return-act-prepare/' + act.id
    }

    /**
     * Фильтрует список пользователей непустым списком хабов по дефолтному хабу
     * @param users
     * @param hubs
     */
    public filterUsersListByHubsList(users: (User | null)[], hubs: ((Hub | number)[]) | null) {
        if (null === hubs || !hubs.length) {
            return users;
        }

        return users.filter((user: User | null) => {
            if (null === user || !user.hasOwnProperty('default_hub_id')) {
                return true;
            }

            return !!hubs.find((item: Hub | number) => {
                if (typeof item === 'number') {
                    return item === user.default_hub_id;
                } else {
                    return item.id === user.default_hub_id;
                }
            });
        });
    }

    /**
     * Отфильтровать пользователей по смене
     * @param users
     * @param shiftNumber
     */
    public filterUsersListByShiftNumber(users: User[], shiftNumber: number) {
        return users.filter(user => {
            if (!user) {
                return true;
            }

            switch (shiftNumber) {
                case 1:
                    return user.work_is_first_shift;
                    break;
                case 2:
                    return user.work_is_second_shift;
                    break;
            }

            return true;
        })
    }

    /**
     * Возвращает нужную форму существительного рядом с числительным
     * @param number
     * @param wordForms
     */
    public getPluralEnding(number: number, wordForms: string[]) {
        const cases = [2, 0, 1, 1, 1, 2];
        return wordForms[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
    }

    /**
     * Возращает настройку клиента
     * @param settings
     * @param key
     */
    public getClientSetting(settings, key) {
        const setting = settings.find(value => value.setting_key.key === key);
        if (setting) {
            return setting.setting_value;
        }
        return null;
    }

    public getPaymentProviderName(payment: Ibox | any, nullIfMissing: boolean = false): string | null {
        const providerId = payment.hasOwnProperty('provider') ? payment.provider : null;
        const provider = Ibox.PROVIDERS.find(lookupProvider => lookupProvider.id === providerId);

        if (provider) {
            return provider.name;
        }

        if (nullIfMissing) {
            return null;
        }

        return 'Неизвестно';
    }

    public getPaymentProviderLabelClassName(payment: Ibox | any, includeBase: boolean = true): string {
        let classNames = [];
        if (includeBase) {
            classNames.push('payment-provider-label-base');
        }

        const providerName = this.getPaymentProviderName(payment, true);
        if (providerName) {
            classNames.push('payment-provider-' + providerName.toLowerCase());
        }

        return classNames.join(' ');
    }

    isProviderIbox(terminal_type: number) {
        return terminal_type === Ibox.PROVIDER_IBOX;
    }

    isProviderAssist(terminal_type: number) {
        return terminal_type === Ibox.PROVIDER_ASSIST;
    }

    isMainPPAct(type: number) {
        return type === PpAct.TYPE_MAIN;
    }

    public getSignatureOriginDescription(signatureOrigin): string | null {
        const origin = OrderSignature.ORIGINS.find(origin => origin.id === signatureOrigin);
        if (origin) {
            return origin.description;
        }

        return null;
    }

    /**
     * Название статуса агентского отчёта
     * @param status
     */
    public getAgentReportStatusName(status: number) {
        return AgentReport.STATUS_DESCRIPTIONS[status - 1];
    }

    /**
     * Считает сумму с НДС
     * @param sum
     * @param ndsValue
     */
    public calcSumWithNds(sum: number, ndsValue: number) {
        return Math.round((100 + ndsValue) * sum) / 100;
    }

    /**
     * Считает сумму НДС
     * @param sum
     * @param ndsValue
     */
    public calcNdsAmount(sum: number, ndsValue: number) {
        return Math.round(ndsValue * sum) / 100;
    }

    public getInstallationServiceStatus(order: Order, forceDisplay = false): string|null {
        if (-1 === [
            Order.STATUS_FINISH,
            Order.STATUS_FAIL_PARTIAL,
            Order.STATUS_REFUSE,
        ].indexOf(order.status)) {
            if (forceDisplay) {
                return 'Ожидают закрытия заказа';
            }

            return null;
        }

        switch (order.is_installation_service) {
            case Order.INSTALLATION_SERVICE_NOT_DECLARED:
                return 'Отсутствуют';

            case Order.INSTALLATION_SERVICE_NONE:
                return 'Не оказаны';

            case Order.INSTALLATION_SERVICE_PARTIAL:
                return 'Оказаны частично';

            case Order.INSTALLATION_SERVICE_FULL:
                return 'Оказаны';
            default:
                return 'Неизвестно';
        }
    }

    public getInstallationServiceClasses(order) {
        if (-1 === [
            Order.STATUS_FINISH,
            Order.STATUS_FAIL_PARTIAL,
            Order.STATUS_REFUSE,
        ].indexOf(order.status)) {
            return 'text-muted';
        }

        switch (order.is_installation_service) {
            case Order.INSTALLATION_SERVICE_NOT_DECLARED:
                return 'text-muted';

            case Order.INSTALLATION_SERVICE_NONE:
                return 'bg-danger text-white';

            case Order.INSTALLATION_SERVICE_PARTIAL:
                return 'bg-warning';

            case Order.INSTALLATION_SERVICE_FULL:
                return 'bg-success text-white';

            default:
                return 'bg-danger';
        }
    }

    phoneClean(phone) {
        phone = phone
            .replace(/\D/g, '')
            .substring(0, 11);

        return phone;
    }
    public inventivStatus(status: number) {
        const inventivStatus = this.inventivActsStatuses.find(item => item.id === status);
        if (!inventivStatus) {
            return 'Неизвестный status';
        }
        return inventivStatus.name;
    }

    getStatusesForEditZorder() {
        if (this.checkPermissions('log:zorder-change-any-status')) {
            return Zorder.STATUSES_FOR_EDIT_FOR_ADMIN;
        }

        return Zorder.STATUSES_FOR_EDIT;
    }

    checkIsAllowEditZorder(status: number) {
        if (this.checkPermissions('log:zorder-change-any-status')) {
            return true;
        }
        if (status == Zorder.STATUS_DONE || status == Zorder.STATUS_CANCEL || status == Zorder.STATUS_ON_STOCK) {
            return false;
        }
        return true;
    }

    /**
     * Название статуса забора
     * @param num
     */
    public getZOrderStatuses() {
        const statuses = [
            'Новый',
            'Подтвержден',
            'Выполнен',
            'Отмена',
            'Выполняется',
            'Перенос',
            'На складе',
            'Отказ на месте',
        ];

        return statuses;
    }
}
