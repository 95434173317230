import {Component, OnInit, ViewChild} from '@angular/core';
import {CargoCourierWorkday, CargoTariff, ClientInfo, CourierUser, User, UserHub} from "../../service/models";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {LaraService} from "../../service/lara.service";
import {DialogService} from "../../components/dialog/dialog.service";
import {Title} from "@angular/platform-browser";
import {DatePipe} from "@angular/common";
import {CurrentUserService} from "../../service/current-user.service";
import {EventerService} from "../../service/eventer.service";
import {HelpersService} from "../../service/helpers.service";
import {Subject} from "rxjs";

@Component({
    selector: 'app-cargo-workday',
    templateUrl: './cargo-workday.component.html',
    styleUrls: ['./cargo-workday.component.scss']
})
export class CargoWorkdayComponent implements OnInit {
    @ViewChild('closeModal') private closeModal;

    public loadingWorkdays = false;
    public loadingWorkday = false;
    public savingWorkday = false;
    public loadingWorkdayLogs = false;
    public loadedOnce = false;

    public selectedClients: ClientInfo[] = [];

    public workdays: CargoCourierWorkday[];
    public workday: CargoCourierWorkday;
    public workdaysSummary: {};

    public formFilter: FormGroup;
    public formEdit: FormGroup;

    public headerFilter: any = [];

    public filterHub;
    public filterCouriers;
    public filterDateFrom;
    public filterIsApproved: boolean = false;
    public filterIsCanceled: boolean = false;

    public state = {take: 50, skip: 0, filter: ''};

    public selectedHubId: number = null;
    public selectedHubs: UserHub[] = [];

    public selectedUsers: CourierUser = null;
    public appHubChangeEventSubject: Subject<any> = new Subject<any>();

    public cargoTariffsList: CargoTariff[] = null;
    public cargoTariffsListLoading: boolean = false;
    public cargoTariffsListFiltered: CargoTariff[] = [];

    public modalEdit: string = null;

    public workdayLogs: Object[] = [];

    @ViewChild('input_datestart') input_datestart;

    constructor(
        private api: LaraService,
        public dialog: DialogService,
        private title: Title,
        private datePipe: DatePipe,
        private currentUserService: CurrentUserService,
        private eventerService: EventerService,
        protected helpers: HelpersService,
    ) {
        this.formEdit = new FormGroup({
            time_start_hours: new FormControl(0, Validators.required),
            time_start_minutes: new FormControl(0, Validators.required),
            time_end_hours: new FormControl(0, Validators.required),
            time_end_minutes: new FormControl(0, Validators.required),

            mileage: new FormControl(0, Validators.required),
            kgt_payments: new FormControl(0, Validators.required),
            cargo_tariff_id: new FormControl(0, Validators.required),
            salary: new FormControl(0, Validators.required),

            logistic_notes: new FormControl('', Validators.required),
        });


        this.eventerService.primaryHubChange.subscribe((hub) => {
            this.onChangeHub(hub);
        });
    }

    ngOnInit() {
        this.currentUserService.get().subscribe(async (user: User) => {
            let hub = this.currentUserService.getCurrentHub();

            this.selectedHubs = [hub,];
            this.selectedHubId = hub.id;
        });

        this.getCargoTariffsList();
    }

    /**
     * Преобразует класс объектов из ответа от API
     * @param workdays
     */
    cast(workdays: CargoCourierWorkday[]) {
        return workdays.map((workday: CargoCourierWorkday) => {
            return new CargoCourierWorkday(workday);
        });
    }

    /**
     * Можно уже прожать кнопочку "Показать"?
     */
    readyToLoadWorkdays() {
        if (this.selectedHubId && this.filterDateFrom) {
            return true;
        }

        return false;
    }

    /**
     * Обработчик изменения в селекторе курьера
     * @param users
     */
    onSelectCourier(users: CourierUser) {
        this.selectedUsers = users;
    }

    /**
     * Обработчик изменения в селекторе хаба
     * @param hub
     */
    onChangeHub(hub) {
        this.selectedHubId = hub.id;
        this.selectedHubs = [hub,];
        this.appHubChangeEventSubjectEmit();

        this.loadWorkdays();
    }

    /**
     * Загружает список рабочих дней курьеров-пятитонников по выбранным фильтрам
     */
    public loadWorkdays() {
        this.loadingWorkdays = true;
        this.loadedOnce = true;

        const filter: any = {};
        filter.date_from = this.filterDateFrom;

        filter.skip = this.state.skip;
        filter.pageSize = this.state.take;
        filter.hub_id = this.selectedHubId;
        if (this.selectedUsers) {
            filter.courier_id = this.selectedUsers.id;
        }

        filter.is_approved = this.filterIsApproved;
        filter.is_canceled = this.filterIsCanceled;

        const headerFilter = this.headerFilter;
        filter.head = headerFilter;

        this.api.getCargoCouriersWorkdays(filter).subscribe(data => {
            this.workdays = this.cast(data);
            this.loadingWorkdays = false;
        });
    }

    /**
     * Обработчик изменения фильтра по хабу - отправит новое фильтрующее значение селектору курьера
     */
    appHubChangeEventSubjectEmit() {
        this.appHubChangeEventSubject.next({type: 'filterIncludeHubsUpdated', filterIncludeHubs: this.selectedHubs});
    }

    /**
     * Обработчик изменения фильтра по дате рабочего дня
     */
    onDateChange() {
        this.filterDateFrom = this.helpers.formatDateForSQL(this.input_datestart.nativeElement.value);
    }

    /**
     * Обработчик клика по фильтру одобренных показателей
     * @param event
     */
    onIsApprovedClick(event) {
        this.filterIsApproved = !!event.target.checked;
    }

    /**
     * Обработчик клика по фильтру аннулированных показателей
     * @param event
     */
    onIsCanceledClick(event) {
        this.filterIsCanceled = !!event.target.checked;
    }

    /**
     * Обработчик клика по кнопке загрузки рабочего дня
     */
    onLoadClick() {
        this.loadWorkdays();
    }

    /**
     * Обработчик изменения страницы просмотра
     * @param event
     */
    onPageChange(event) {
        console.info(event);
    }

    /**
     * Редактирование рабочего дня - запросит актуальное значение и покажет модальное окно выбранного типа
     * @param what
     * @param workdayId
     */
    showEdit(what: string, workdayId: number) {
        let workdayLocal = this.workdays.find(item => {
            console.info(item.id, workdayId);
            return item.id === workdayId;
        });

        if (!workdayLocal) {
            return;
        }

        this.cargoTariffsListFiltered = [];
        this.modalEdit = what;
        this.loadingWorkday = true;

        this.api.getCourierCargoWorkday(workdayLocal.courier_id, workdayLocal.date).subscribe(workday => {
            this.workday = new CargoCourierWorkday(workday);
            this.updateCargoTariffsListFiltered();

            this.formEdit.controls.time_start_hours.setValue(this.workday.time_start_hours);
            this.formEdit.controls.time_start_minutes.setValue(this.workday.time_start_minutes);
            this.formEdit.controls.time_end_hours.setValue(this.workday.time_end_hours);
            this.formEdit.controls.time_end_minutes.setValue(this.workday.time_end_minutes);

            this.formEdit.controls.mileage.setValue(this.workday.mileage);
            this.formEdit.controls.kgt_payments.setValue(this.workday.kgt_payments);
            this.formEdit.controls.cargo_tariff_id.setValue(this.workday.cargo_tariff_id);
            this.formEdit.controls.salary.setValue(this.workday.salary);

            this.formEdit.controls.logistic_notes.setValue(this.workday.logistic_notes);

            this.loadingWorkday = false;
        }, err => {
            this.loadingWorkday = false;
            this.hideModal();
        });
    }

    /**
     * Просмотр журнала записи - запросит историю и покажет её в модальном окне
     * @param id
     */
    workdayLogsOpen(id) {
        console.info('logs', id);
    }

    /**
     * Получает список тарифов курьеров-пятитонников
     */
    getCargoTariffsList() {
        this.cargoTariffsListLoading = true;
        this.api.getCargoTariffsList().subscribe(data => {
            this.cargoTariffsList = data.map(item => {
                return new CargoTariff(item);
            });

            this.cargoTariffsListLoading = false;
        });
    }

    /**
     * Обновляет список тарифов для назначения на курьера
     * @private
     */
    private updateCargoTariffsListFiltered() {
        this.cargoTariffsListFiltered = this.cargoTariffsList.filter((cargoTariffItem: CargoTariff) => {
            return (this.workday.courier_default_hub_id === cargoTariffItem.hub_id || this.workday.cargo_tariff_id === cargoTariffItem.id);
        }).map((cargoTariffItem: CargoTariff) => {
            cargoTariffItem.is_foreign_hub_tariff = (this.workday.courier_default_hub_id !== cargoTariffItem.hub_id);

            return cargoTariffItem;
        });
    }

    /**
     * Устанавливает тариф (а надо оно тут?)
     * @param event
     */
    setCargoTariff(event) {
        console.info('setCargoTariff', event);
    }

    /**
     * Закрывает модальное окно редактора рабочего дня
     */
    hideModal() {
        this.modalEdit = null;
        this.workday = null;

        this.closeModal.nativeElement.click();
    }

    /**
     * Проверяет возможность записать изменения в редакторе рабочего дня
     */
    canUpdateWorkday() {
        if ('logistic_note' === this.modalEdit) {
            return true;
        }

        // todo check
    }

    /**
     * Записывает изменения из редактора рабочего дня
     */
    updateWorkday() {
        console.info('updateWorkday', this.modalEdit, this.workday, this.formEdit.value);

        let payload = {};

        switch (this.modalEdit) {
            case 'time':
                payload['time_start'] = this.formEdit.controls.time_start_hours.value + ':' + this.formEdit.controls.time_start_minutes.value;
                payload['time_end'] = this.formEdit.controls.time_end_hours.value + ':' + this.formEdit.controls.time_end_minutes.value;
                break;
            case 'mileage':
            case 'kgt_payments':
            case 'cargo_tariff_id':
            case 'salary':
            case 'logistic_notes':
                payload[this.modalEdit] = this.formEdit.controls[this.modalEdit].value;
                break;
        }

        if (Object.keys(payload).length > 0) {
            this.savingWorkday = true;
            this.api.updateCourierCargoWorkday(this.workday.courier_id, this.workday.date, this.modalEdit, payload).subscribe(data => {
                this.workday = data;
                this.savingWorkday = false;
                this.hideModal();
                this.loadWorkdays();
            }, err => {
                this.savingWorkday = false;
                this.hideModal();
                this.loadWorkdays();
            });
        }
    }

    /**
     * Подтверждение показателей рабочего дня курьера для дальнейшей выплаты зарплаты
     * @param workday
     */
    approve(workday: CargoCourierWorkday) {
        this.api.approveCargoCourierWorkday(workday.courier_id, workday.date).subscribe(data => {
            this.loadWorkdays();
        });
    }

    /**
     * Отмена подтверждения показателей рабочего дня курьера
     * @param workday
     */
    unApprove(workday: CargoCourierWorkday) {
        this.api.unApproveCargoCourierWorkday(workday.courier_id, workday.date).subscribe(data => {
            this.loadWorkdays();
        });
    }

    /**
     * Аннулирование рабочего дня курьера
     * @param workday
     */
    cancel(workday: CargoCourierWorkday) {
        this.api.cancelCargoCourierWorkday(workday.courier_id, workday.date).subscribe(data => {
            this.loadWorkdays();
        });
    }

    /**
     * Отмена аннулирования рабочего дня курьера
     * @param workday
     */
    unCancel(workday: CargoCourierWorkday) {
        this.api.unCancelCargoCourierWorkday(workday.courier_id, workday.date).subscribe(data => {
            this.loadWorkdays();
        });
    }

    /**
     * Копирует значение вычисленной зарплаты в назначенную
     */
    cloneSalary() {
        if (!this.workday) {
            return;
        }

        this.workday.salary = this.workday.salary_calculated;
        this.formEdit.controls.salary.setValue(this.workday.salary_calculated);
    }
}
