import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SearchComponent} from '../search/search.component';

import {ActlistComponent} from './../storage/actlist/actlist.component';
import {BoardOnlineComponent} from './../storage/board-online/board-online.component';
import {IndexComponent} from 'app/storage/transfer/index/index.component';

import {MactlistComponent} from '../storage/mactlist/mactlist.component';

import {ProcessingReturnsComponent} from '../storage/processing-returns/processing-returns.component';
import {ReceptionOrdersComponent} from '../storage/reception-orders/reception-orders.component';
import {ReturnOrdersComponent} from '../storage/transfer/return-orders/return-orders.component';

import {TransferTabComponent} from 'app/storage/transfer/transfer-tab/transfer-tab.component';
import {TurnWorkComponent} from '../turn-work/turn-work.component';
import {UserResolver} from '../users/userResolver.service';
import {ReturnactsComponent} from '../storage/returnacts/returnacts.component';
import {ActCreateComponent} from '../storage/act-create/act-create.component';
import {OrdersListComponent} from '../orders-list/orders-list.component';
import {OrdersCheckComponent} from '../logistics/orders-check/orders-check.component';
import localeRu from '@angular/common/locales/ru';
import {registerLocaleData} from '@angular/common';
import {CreateTeComponent} from '../storage/create-te/create-te.component';
import {TeListComponent} from '../storage/te-list/te-list.component';
import {TransitListComponent} from '../storage/transit-list/transit-list.component';
import {ZordersListComponent} from '../logistics/zorders-list/zorders-list.component';
import {CourierStatusComponent} from '../logistics/courier-status/courier-status.component';
import {CourierDocsComponent} from '../logistics/courier-docs/courier-docs.component';
import {UnrecognizedAddressesComponent} from '../logistics/unrecognized-addresses/unrecognized-addresses.component';
import {AddressesCacheReattachmentComponent} from '../addresses/addresses-cache-reattachment/addresses-cache-reattachment.component';
import {CourierTarifficatorComponent} from '../logistics/courier-tarifficator/courier-tarifficator.component';
import {ProblemsComponent} from '../logistics/problems/problems.component';
import {PushNotificationsComponent} from '../mailer/push-notifications/push-notifications.component';
import {SberbankControlComponent} from '../logistics/sberbank-control/sberbank-control.component';
import {PreAssemblyComponent} from '../storage/pre-assembly/pre-assembly.component';
import {RightsFailureComponent} from '../components/rights-failure/rights-failure.component';
import {RegionalOrdersComponent} from '../logistics/regional-orders/regional-orders.component';
import {PowerOfAttorneyComponent} from '../storage/printer/power-of-attorney/power-of-attorney.component';
import {RefusedOnSpotComponent} from '../logistics/refused-on-spot/refused-on-spot.component';
import {OrdersPlacementCheckComponent} from '../storage/orders-placement-check/orders-placement-check.component';
import {OrdersPlacementReportComponent} from '../storage/orders-placement-report/orders-placement-report.component';
import {PalletListComponent} from '../storage/pallet/pallet-list/pallet-list.component';
import {PalletItemComponent} from '../storage/pallet/pallet-item/pallet-item.component';
import {TransitsListComponent} from '../storage/transits/transits-list/transits-list.component';
import {TransitsItemComponent} from '../storage/transits/transits-item/transits-item.component';
import {TransitReceiveComponent} from '../storage/transits/transit-receive/transit-receive.component';
import {SpbControlComponent} from '../logistics/spb-control/spb-control.component';
import {CargoWorkdayComponent} from '../logistics/cargo-workday/cargo-workday.component';
import {CourierExtraExpensesComponent} from '../logistics/courier-extra-expenses/courier-extra-expenses.component';
import {OrdersSignaturesVerificationQueueComponent} from '../client-service/orders-signatures-verification-queue/orders-signatures-verification-queue.component';
import {DispatchedNotificationsComponent} from '../mailer/dispatched-notifications/dispatched-notifications.component';
import {SecondShiftControlAssemblingComponent} from '../storage/second-shift-control-assembling/second-shift-control-assembling.component';
import {SecondShiftAssemblingComponent} from '../storage/second-shift-assembling/second-shift-assembling.component';

import {CourierScheduleComponent} from '../logistics/courier-schedule/courier-schedule.component';
import {PpActsComponents} from '../storage/pp-acts/pp-acts.components';
import {PpActClientComponent} from '../storage/pp-act-client/pp-act-client.component';
import {ControlCourierMactComponent} from '../storage/control-courier-mact/control-courier-mact.component';
import {ReturnActCreateComponent} from '../storage/return-act-create/return-act-create.component';
import {LostOrdersComponent} from '../storage/lost-orders/lost-orders.component';
import {ReturnActPrepareComponent} from '../storage/return-act-prepare/return-act-prepare.component';
import {ReturnActsArchivariusComponent} from '../storage/return-acts-archivarius/return-acts-archivarius.component';
import {ReturnActsFixerComponent} from 'app/storage/return-acts-fixer/return-acts-fixer.component';
import {ReturnActsTransferCourierComponent} from '../storage/return-acts-transfer-courier/return-acts-transfer-courier.component';
import {HhcCourierAssemblingComponent} from '../storage/hhc-courier-assembling/hhc-courier-assembling.component';
import {
    HhcCourierAssemblingLegacyComponent
} from '../storage/hhc-courier-assembling-legacy/hhc-courier-assembling-legacy.component';

registerLocaleData(localeRu);

const routes: Routes = [
    {path: 'search', component: SearchComponent},
    {path: 'sklad/index', component: IndexComponent},
    {path: 'sklad/onlinetablo', component: BoardOnlineComponent},
    {path: 'sklad/reception', component: TransferTabComponent},
    {path: 'sklad/actlist', component: ActlistComponent},
    {path: 'sklad/pp-acts', component: PpActsComponents},
    {path: 'sklad/pp-client-acts', component: PpActClientComponent},
    {path: 'sklad/mactlist', component: MactlistComponent},
    {path: 'sklad/control-courier-mact', component: ControlCourierMactComponent},
    {path: 'sklad/turn', component: TurnWorkComponent},

    {path: 'sklad/return-act-create', component: ReturnActCreateComponent},
    {path: 'sklad/return-act-prepare/:id', component: ReturnActPrepareComponent},
    {path: 'sklad/return', component: ProcessingReturnsComponent},
    {path: 'sklad/reception_order', component: ReceptionOrdersComponent},
    {path: 'sklad/return_acts', component: ReturnactsComponent},
    {path: 'sklad/return-acts-archivarius', component: ReturnActsArchivariusComponent},
    {path: 'sklad/return-acts-fixer', component: ReturnActsFixerComponent},
    {path: 'sklad/return-acts-transfer-courier', component: ReturnActsTransferCourierComponent},
    {path: 'sklad/act-create', component: ActCreateComponent},
    {path: 'sklad/second-shift/assembling-control', component: SecondShiftControlAssemblingComponent},
    {path: 'sklad/second-shift/assembling', component: SecondShiftAssemblingComponent},
    {path: 'zorders/list', component: ZordersListComponent},
    {path: 'courier-docs', component: CourierDocsComponent},
    {path: 'logistics/courier-status', component: CourierStatusComponent},
    {path: 'logistics/unrecognized-addresses', component: UnrecognizedAddressesComponent},
    {path: 'logistics/courier-tarifficator', component: CourierTarifficatorComponent},
    {path: 'logistics/problems', component: ProblemsComponent},
    {path: 'logistics/sberbank-control', component: SberbankControlComponent},
    {path: 'logistics/regional-orders', component: RegionalOrdersComponent},
    {path: 'logistics/refused-on-spot', component: RefusedOnSpotComponent},
    {path: 'logistics/spb-control', component: SpbControlComponent},
    {path: 'logistics/cargo-workday', component: CargoWorkdayComponent},
    {path: 'logistics/courier-extra-expenses', component: CourierExtraExpensesComponent},
    {path: 'logistics/courier-schedule', component: CourierScheduleComponent},
    {path: 'logistics/orders-signatures-verification', component: OrdersSignaturesVerificationQueueComponent},

    {path: 'sklad/lost-orders', component: LostOrdersComponent},

    {path: 'orders/list', component: OrdersListComponent},
    {path: 'orders/list/ondelivery', component: OrdersListComponent},
    {path: 'orders/list/check-orders', component: OrdersCheckComponent},
    {path: 'orders/list/onware', component: OrdersListComponent},

    {path: 'sklad/create-te', component: CreateTeComponent},
    {path: 'sklad/pallets', component: PalletListComponent},
    {path: 'sklad/pallets/:id', component: PalletItemComponent},
    {path: 'sklad/transits', component: TransitsListComponent},
    {path: 'sklad/transits/receive', component: TransitReceiveComponent},
    {path: 'sklad/transits/:id', component: TransitsItemComponent},
    {path: 'sklad/te-list', component: TeListComponent},
    {path: 'sklad/transit-list', component: TransitListComponent},
    {path: 'sklad/pre-assembly', component: PreAssemblyComponent},
    {path: 'sklad/placement-check', component: OrdersPlacementCheckComponent},
    {path: 'sklad/placement-report', component: OrdersPlacementReportComponent},
    {path: 'sklad/return-orders', component: ReturnOrdersComponent},
    {path: 'sklad/hhc-courier-assembling', component: HhcCourierAssemblingComponent},
    {path: 'sklad/hhc-courier-assembling-legacy', component: HhcCourierAssemblingLegacyComponent},

    {path: 'mailer/push-notifications', component: PushNotificationsComponent},
    {path: 'mailer/dispatched-notifications', component: DispatchedNotificationsComponent},

    {path: 'rights-failure', component: RightsFailureComponent},
    {path: 'power-of-attorney', component: PowerOfAttorneyComponent},

    {path: 'addresses/cache-reattachment', component: AddressesCacheReattachmentComponent},
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [UserResolver],
})

export class AdminRoutingModule {
}
