import {Component, OnDestroy, OnInit} from '@angular/core';
import {CurrentUserService} from '../../service/current-user.service';
import {NavigationEnd, Router} from '@angular/router';
import {User} from '../../service/models';
import {LaraService} from '../../service/lara.service';
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {EventerService} from '../../service/eventer.service';

declare interface RouteInfo {
    path: string;
    title: string;
    icon?: string;
    problemsCount?: boolean;
    ordersSignaturesVerificationQueueCounter?: boolean;
    refusedOrdersQueueCounter?: boolean;
    urlDiversion: boolean;
    simpleLink?: boolean;
    view?: (user: User) => boolean;
    items?: RouteInfo[];
}

@Component({
    selector: 'app-admin-navbar',
    templateUrl: './admin-navbar.component.html',
    styleUrls: ['./admin-navbar.component.scss'],
})

export class AdminNavbarComponent implements OnInit, OnDestroy {
    menu: RouteInfo[];
    public user: User;
    public loading = false;
    public activatedParentItems: string[] = [];
    public sidebarIsOpened = false;
    public sidebarIsMinified = false;
    private routerSubscription: Subscription;
    private sidebarToggleSubscription: Subscription;
    private activeRootItem: RouteInfo;
    private activeItem: RouteInfo;
    private menuSource: RouteInfo[] = [
        {
            title: 'iT Отдел',
            path: '/admin-service/create-courier',
            icon: 'fa fa-user-shield',
            urlDiversion: false,
            items: [
                {
                    path: '/admin-service/create-courier',
                    title: 'Создание курьера (устарел)',
                    urlDiversion: true,
                    view: (user: User) => user.inPermission('staff:courier-create'),
                },
                {
                    path: '/admin-service/create-client',
                    title: 'Создание клиента',
                    urlDiversion: true,
                    view: (user: User) => user.inPermission('clients:client-create'),
                },
            ]
        },
        {
            title: 'Клиенты',
            path: '/clients/index',
            urlDiversion: false,
            icon: 'icon-findocs',
            items: [
                {
                    path: '/clients/index',
                    title: 'Клиенты',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('clients:view'),
                },
                {
                    path: '/clients/matrixes',
                    title: 'Матрицы',
                    urlDiversion: false,
                    items: [
                        {
                            path: '/clients/matrixes/matrix-moscow',
                            title: 'Тарифная матрица М и МО',
                            urlDiversion: false,
                            view: (user: User) => true,
                        },
                        {
                            path: '/clients/matrixes/matrix-moscow-orders-amount',
                            title: 'Тарифная матрица М и МО по объёму заказов',
                            urlDiversion: false,
                            view: (user: User) => true,
                        },
                        {
                            path: '/clients/matrixes/matrix-hubs/2',
                            title: 'Матрица СПб',
                            urlDiversion: false,
                            view: (user: User) => true,
                        },
                        {
                            path: '/clients/matrixes/matrix-kgt',
                            title: 'КГТ матрица',
                            urlDiversion: false,
                            view: (user: User) => true,
                        },
                        {
                            path: '/clients/matrixes/matrix-takeaway-moscow',
                            title: 'Матрица заборов М и МО',
                            urlDiversion: false,
                            view: (user: User) => true,
                        },
                        {
                            path: '/clients/matrixes/matrix-takeaway-cfo',
                            title: 'Матрица заборов ЦФО',
                            urlDiversion: false,
                            view: (user: User) => true,
                        },
                        {
                            path: '/clients/matrixes/matrix-topdelivery',
                            title: 'Матрица TopDelivery',
                            urlDiversion: false,
                            view: (user: User) => true,
                        },
                    ],
                    view: (user: User) => user.inPermission('matrix:update-client'),
                },
                {
                    path: '/clients/calculators',
                    title: 'Калькулятор',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('clients:calculators-view'),
                },
            ],
        },
        {
            title: 'Пользователи',
            path: '/users/index',
            icon: 'icon-retunrs',
            urlDiversion: false,
            items: [
                {
                    path: '/users/index',
                    title: 'Пользователи',
                    urlDiversion: false,
                    view: (user: User) => (user.inPermission('users:create') || user.inPermission('users:update')),
                },
                {
                    path: '/users/roles-control',
                    title: 'Управление ролями',
                    urlDiversion: false,
                    view: (user: User) => (user.inPermission('roles:create') || user.inPermission('user-roles:update')),
                },
            ],
        },
        {
            title: 'Персонал',
            path: '/staff/couriers',
            icon: 'icon-user',
            urlDiversion: false,
            items: [
                {
                    path: '/staff/couriers',
                    title: 'Курьеры',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('staff:couriers'),
                },
                {
                    path: '/staff/terminals',
                    title: 'Терминалы',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('staff:terminals:view'),
                },
                {
                    path: '/staff/penalties',
                    title: 'Штрафы и жалобы',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('staff:penalties'),
                },
                {
                    path: '/staff/penalty-rules',
                    title: 'Причины штрафов',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('staff:penalties'),
                },
            ],
        },
        {
            title: 'Возвраты',
            path: '/admin/sklad/return-acts-transfer-courier',
            urlDiversion: false,
            icon: 'fa fa-trash-restore',
            items: [
                {
                    path: '/admin/sklad/return-acts-transfer-courier',
                    title: 'Передача возвратов курьеру',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('storage:return'),
                },
                {
                    path: '/admin/sklad/return-act-create',
                    title: 'Обработка возвратов',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('storage:return'),
                },
                {
                    path: '/admin/sklad/return_acts',
                    title: 'Акты возврата',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('storage:return-acts'),
                },
                {
                    path: '/admin/sklad/return-acts-archivarius',
                    title: 'В архив',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('storage:return-acts:archive'),
                },
                {
                    path: '/admin/sklad/return-acts-fixer',
                    title: 'Фиксики ™',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('storage:return-acts:fixer'),
                },
            ]
        },
        {
            title: 'Склад',
            path: '/admin/sklad/onlinetablo',
            urlDiversion: false,
            icon: 'icon-in-out',
            items: [
                {
                    path: '/admin/sklad/onlinetablo',
                    title: 'Табло',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('storage:online-tablo'),
                },
                {
                    path: '/admin/sklad/reception',
                    title: 'Прием курьера',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('storage:reception-courier'),
                },
                {
                    path: '/admin/sklad/return-orders',
                    title: 'Быстрый возврат',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('storage:return-orders'),
                },
                {
                    path: '/admin/sklad/reception_order',
                    title: 'Прием заказов',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('storage:reception-order'),
                },
                {
                    path: '/sberbank',
                    title: 'Сбербанк',
                    urlDiversion: false,
                    view: (user: User) => true,
                },
                // {
                //     path: '/admin/sklad/second-shift/assembling-control',
                //     title: 'Контроль сборки 2 смены',
                //     urlDiversion: false,
                //     view: (user: User) => user.inPermission('storage:second-shift-assembling'),
                // },
                // {
                //     path: '/admin/sklad/second-shift/assembling',
                //     title: 'Сборка 2 смены',
                //     urlDiversion: false,
                //     view: (user: User) => user.inPermission('storage:second-shift-assembling'),
                // },
                {
                    path: '/admin/sklad/lost-orders',
                    title: 'Утраченные товары',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('storage:lost-orders'),
                },
                {
                    path: '/admin/sklad/pp-acts',
                    title: 'Акты ПП/недостачи/излишков',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('storage:act-list'),
                },
                {
                    path: '/admin/sklad/pp-client-acts',
                    title: 'Акты ПП от клиентов',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('storage:act-list'),
                },
                {
                    path: '/admin/sklad/mactlist',
                    title: 'Акты МатОтв',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('storage:mact-list'),
                },
                {
                    path: '/admin/sklad/control-courier-mact',
                    title: 'Контроль актов МатОтв',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('storage:control-mact'),
                },
                {
                    path: '/admin/sklad/pallets',
                    title: 'Палеты',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('storage:transits'),
                },
                {
                    path: '/admin/sklad/transits',
                    title: 'Перемещения',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('storage:transits'),
                },

                {
                    path: '/admin/sklad/transits/receive',
                    title: 'Принять перемещение',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('storage:transits'),
                },
                // {
                //     path: '/admin/sklad/create-te',
                //     title: 'Собрать ТД',
                //     urlDiversion: false,
                //     view: (user: User) => true,
                // },
                // {
                //     path: '/admin/sklad/te-list',
                //     title: 'Палеты ТД',
                //     urlDiversion: false,
                //     view: (user: User) => true,
                // },
                /**
                 * RESEARCH-939
                 */
                // {
                //     path: '/admin/sklad/transit-list',
                //     title: 'Старые перемещения',
                //     urlDiversion: false,
                //     view: (user: User) => true
                // },
                {
                    path:
                        'https://docs.google.com/document/d/1apfIVyNRSdXWeTGYFjKDaYBva55wn0d0WSkYroQIkvo/edit',
                    title: 'Инструкция склад',
                    urlDiversion: true,
                    simpleLink: true,
                    view: (user: User) => (!user.isRoleCustomer() && !user.isRoleCourier()),
                },
                // {
                //     path: '/admin/sklad/actlist',
                //     title: 'Акты ПП (старые)',
                //     urlDiversion: false,
                //     view: (user: User) => user.inPermission('storage:act-list'),
                // },
                {
                    path: '/sklad/pre-assembly',
                    title: 'Предсборка',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('storage:predsbor'),
                },
                {
                    path: '/sklad/placement-check',
                    title: 'Проверка размещения',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('storage:orders-placement-check'),
                },
                {
                    path: '/admin/sklad/placement-report',
                    title: 'Отчет о размещении',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('storage:orders-placement-report'),
                },
                {
                    path: '/sklad/hhc-courier-assembling',
                    title: 'Сборка курьера 📱',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('storage:predsbor'),
                },
                // {
                //     path: '/sklad/hhc-courier-assembling-legacy',
                //     title: 'Сборка курьера LGC 📱',
                //     urlDiversion: false,
                //     view: (user: User) => user.inPermission('storage:predsbor'),
                // },
                {
                    path: '/storage/transport-waybills',
                    title: 'Транспортные накладные',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('storage:transport-waybills'),
                    items: [
                        {
                            path: '/storage/transport-waybills/create',
                            title: 'Новая накладная',
                            urlDiversion: false,
                            view: (user: User) => true,
                        },
                    ],
                },
                {
                    path: '/storage/hub-gates',
                    title: 'Ворота',
                    urlDiversion: false,
                    view: (user: User) => {
                        return user.inPermission('storage:gates:view') ||
                            user.inPermission('storage:gates:edit') ||
                            user.inPermission('storage:gates:change-status');
                    },
                },
                {
                    path: '/storage/inventiv-acts',
                    title: 'Акты Инвентив',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('storage:inventiv-acts'),
                },
            ],
        },
        {
            title: 'ПВЗ',
            path: '/pickup-point/orders-list',
            urlDiversion: false,
            icon: 'icon-pvz',
            items: [
                {
                    path: '/pickup-point/orders-list',
                    title: 'Заказы ПВЗ',
                    urlDiversion: false,
                    view: (user: User) => {
                        return user.inPermission('storage:pickup-point:list-orders') ||
                            user.inPermission('storage:pickup-point:shipping');
                    },
                },
                {
                    path: '/pickup-point/shipping',
                    title: 'Отгрузка заказа',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('storage:pickup-point:shipping'),
                },
            ],
        },
        {
            title: 'Клиентские возвраты',
            path: '/storage/client-return-package',
            urlDiversion: false,
            icon: 'icon-pvz',
            items: [
                {
                    path: '/storage/client-return-package',
                    title: 'Тара клиентских возвратов',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('storage:client-returns-package'),
                },
                {
                    path: '/storage/set-weight-for-returns',
                    title: 'Перевес возвратов',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('storage:client-returns-package'),
                },
            ],
        },
        {
            title: 'Логистика',
            path: '/admin/logistics/courier-status',
            urlDiversion: false,
            icon: 'icon-pvz',
            items: [
                {
                    path: '/admin/logistics/courier-status',
                    title: 'Приход курьеров',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('log:courier-status'),
                },
                {
                    path: '/admin/logistics/unrecognized-addresses',
                    title: 'Нераспознанные адреса',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('log:unrecognized-addresses'),
                },
                {
                    path: '/admin/zorders/list',
                    title: 'Заявки на забор',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('log:zorders-list'),
                },
                {
                    path: '/admin/orders/list',
                    title: 'Распределение',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('log:orders-list'),
                },
                {
                    path: '/main-map',
                    title: 'Карта',
                    urlDiversion: true,
                    view: (user: User) => user.inPermission('log:main-map'),
                },
                // {
                //     path: '/admin/orders/list/ondelivery',
                //     title: 'На доставке',
                //     urlDiversion: false,
                //     view: (user: User) => user.inPermission('log:orders-list'),
                // },
                {
                    path: '/admin/orders/list/check-orders',
                    title: 'Контроль заказов',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('log:orders-list'),
                },
                // {
                //     path: '/admin/orders/list/onware',
                //     title: 'На складе',
                //     urlDiversion: false,
                //     view: (user: User) => user.inPermission('log:orders-list'),
                // },
                {
                    path: '/logist-map',
                    title: 'Курьер-контроль',
                    urlDiversion: true,
                    view: (user: User) => user.inPermission('log:logist-map'),
                },
                {
                    path: '/logistics/courier-control',
                    title: 'BETA Курьер-контроль',
                    urlDiversion: true,
                    view: (user: User) => user.inPermission('log:main-map'),
                },
                {
                    path: '/admin/logistics/courier-schedule',
                    title: 'Курьерский график',
                    urlDiversion: true,
                    view: (user: User) => user.inPermission('log:courier-schedule:view'),
                },
                {
                    path: '/admin/courier-docs',
                    title: 'Курьерские документы',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('log:courier-docs'),
                },
                {
                    path: '/admin/logistics/courier-tarifficator',
                    title: 'Тарификатор курьера',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('log:courier-tarifficator'),
                },
                {
                    path: '/logistics/zone-redactor',
                    title: 'Редактирование зон',
                    urlDiversion: true,
                    view: (user: User) => user.inPermission('log:zone-redactor'),
                },
                {
                    path: '/admin/logistics/problems',
                    title: 'Проблемы',
                    problemsCount: true,
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('log:problems'),
                },
                {
                    path: '/admin/logistics/sberbank-control',
                    title: 'Управление очередью',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('log:sberbank-control'),
                },
                // {
                //     path: '/admin/logistics/regional-orders',
                //     title: 'Региональные заказы',
                //     urlDiversion: false,
                //     view: (user: User) => user.inPermission('log:regional-orders'),
                // },
                {
                    path: '/admin/logistics/refused-on-spot',
                    title: 'Отказы на месте',
                    urlDiversion: false,
                    refusedOrdersQueueCounter: true,
                    view: (user: User) => user.inPermission('log:refused-orders'),
                },
                // {
                //     path: '/admin/logistics/spb-control',
                //     title: 'Контроль заказов в СПб',
                //     urlDiversion: false,
                //     view: (user: User) => user.inPermission('log:spb-control'),
                // },
                {
                    path: '/admin/logistics/cargo-workday',
                    title: 'Рабочий день пятитонника',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('log:cargo-courier-workday'),
                },
                {
                    path: '/admin/logistics/courier-extra-expenses',
                    title: 'Курьерские доплаты',
                    urlDiversion: false,

                    view: (user: User) => user.inPermission('log:manage-extra-expences'),
                },
                {
                    path: '/logistics/doc-return',
                    title: 'Возврат документов',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('log:doc-return-acts'),
                },
                {
                    path: '/admin/logistics/orders-signatures-verification',
                    title: 'Верификация подписей',
                    ordersSignaturesVerificationQueueCounter: true,
                    urlDiversion: false,
                    view: (user: User) => (
                        user.inPermission('orders:verify-signature') || user.inPermission('orders:re-verify-signature')
                    ),
                },
            ],
        },
        {
            title: 'Сервис и услуги',
            path: '/services/requests-list',
            urlDiversion: false,
            icon: 'icon-pvz',
            items: [
                {
                    path: '/services/requests-list',
                    title: 'Заявки',
                    urlDiversion: false,
                    view: (user: User) => (
                        user.inPermission('services:list-requests')
                    ),
                },
            ],
        },
        {
            title: 'Уведомления',
            path: '/admin/mailer/push-notifications',
            urlDiversion: false,
            icon: 'icon-notification',
            items: [
                {
                    path: '/admin/mailer/push-notifications',
                    title: 'Push уведомления',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('staff:couriers:send-push'),
                },
                {
                    path: '/admin/mailer/dispatched-notifications',
                    title: 'Отправленные уведомления',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('staff:dispatched-notifications'),
                },
            ],
        },
        {
            title: 'Статистика',
            path: '/statistics/report-returns',
            icon: 'icon-statistic',
            urlDiversion: false,
            items: [
                {
                    path: '/statistics/daily-meeting',
                    title: 'К собранию',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('stat:daily-meeting'),
                },
                {
                    path: '/statistics/new-stat',
                    title: 'Новая статистика',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('stat:new-stat'),
                },
                {
                    path: '/statistics/main',
                    title: 'Статистика по заказам',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('stat:main-report'),
                },
                {
                    path: '/statistics/report-returns',
                    title: 'Отчёт по возвратам',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('return-report:view'),
                },
                {
                    path: '/statistics/couriers-assembly',
                    title: 'Сборка курьеров',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('stat:shipping-report'),
                },
                {
                    path: '/statistics/e-bulky-pulse',
                    title: 'Пульс Easy Bulky',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('stat:logsis-pulse'),
                },

                {
                    path: '/statistics/bad-addresses',
                    title: 'Проблемные адреса',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('stat:bad-addresses-reports'),
                },
                {
                    path: '/statistics/suspisious-addresses',
                    title: 'Подозрительные адреса',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('stat:bad-addresses-reports'),
                },
                {
                    path: '/statistics/top20-delivery-warehouse',
                    title: 'Топ-20 привоза на склад',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('stat:top20-report'),
                },
                {
                    path: '/statistics/delivery-warehouse',
                    title: 'Привоз на склад',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('stat:received-orders-in-stock-by-day-report'),
                },
                {
                    path: '/statistics/courier-stat',
                    title: 'Статистика по курьерам',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('stat:courier-report'),
                },
                {
                    path: '/statistics/courier-salary',
                    title: 'Выплаты курьерам',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('stat:courier-salary'),
                },
                {
                    path: '/statistics/reasons-perenos',
                    title: 'Причины переноса',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('stat:reasons-perenos'),
                },
                {
                    path: '/statistics/intervals-stat',
                    title: 'Статистика по интервалам',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('stat:intervals'),
                },
                {
                    path: '/statistics/clients-stat',
                    title: 'Статистика по клиентам',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('stat:clients-report'),
                },
                {
                    path: '/statistics/clients-stat-with-filter',
                    title: 'По клиентам с фильтрами',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('stat:clients-report'),
                },
                {
                    path: '/statistics/warehouse-stat',
                    title: 'Складская статистика',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('stat:storeg-report'),
                },
                {
                    path: '/statistics/zorders-stat',
                    title: 'Статистика по заборам',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('stat:fence-report'),
                },
                {
                    path: '/statistics/indicators-im',
                    title: 'Показатели ИМ',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('stat:indicator-im--report'),
                },
                {
                    path: '/statistics/courier-indicators',
                    title: 'Показатели курьера',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('stat:courier-indicators'),
                },
                {
                    path: '/statistics/stat-by-deliverability',
                    title: 'Статистика по доставляемости',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('stat:deliverability-report'),
                },
                {
                    path: '/statistics/accounts-stat',
                    title: 'Статистика для аккаунтов',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('stat:accounts-report'),
                },
                // {
                //     path: '/statistics/quality-by-day-by-client',
                //     title: 'Качество по дням по клиенту',
                //     urlDiversion: false,
                //     view: (user: User) => user.inPermission('stat:quality-by-day-by-client'),
                // },
                {
                    path: '/statistics/fee-breakdown',
                    title: 'Статистика по видам выручки',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('stat:fee-breakdown'),
                },
            ],
        },
        {
            title: 'Бухгалтерия',
            path: '/finance/courier-calculation',
            icon: 'icon-finance',
            urlDiversion: false,
            items: [
                {
                    path: '/finance/close-list',
                    title: 'Лист закрытия',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('buh:close-list'),
                },
                {
                    path: '/finance/close-list-logist',
                    title: 'Лист закрытия логиста',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('log:close-list'),
                },
                {
                    path: '/finance/agent-report',
                    title: 'Агентские отчеты',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('buh:agent-reports'),
                    items: [
                        {
                            path: '/finance/agent-report/summary',
                            title: 'Сводный отчет',
                            urlDiversion: false,
                            view: (user: User) => true,
                        },
                    ],
                },
                {
                    path: '/finance/agent-report-revision',
                    title: 'Сверка агентских отчетов',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('buh:agent-reports'),
                },
                {
                    path: '/finance/bank-payment-orders',
                    title: 'Платёжные поручения',
                    urlDiversion: false,
                    view: (user: User) => {
                        return user.inPermission('accounting:bank-payment-orders:read') ||
                            user.inPermission('accounting:bank-payment-orders:import');
                    },
                },
                {
                    path: '/finance/agent-payment',
                    title: 'Агентские платежи',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('buh:agent-reports'),
                },
                {
                    path: '/finance/courier-calculation',
                    title: 'Расчёт курьера',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('buh:crmoney-view'),
                },
                {
                    path: '/finance/check-close-list',
                    title: 'Сверка листа закрытия',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('buh:close-list'),
                },
                {
                    path: '/finance/courier-salary-fact',
                    title: 'Отчёт по фактической дате ЗП',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('buh:view-salaries-by-date'),
                },
                {
                    path: '/finance/checking-orders',
                    title: 'Проверка заказов',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('buh:orders-report'),
                },
                // {
                //     path: '/finance/e-kassir',
                //     title: 'Электронный кассир',
                //     urlDiversion: false,
                //     view: (user: User) => user.inPermission('buh:e-kassir'),
                // },
                {
                    path: '/finance/kassa-report',
                    title: 'Отчёт по кассе',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('buh:kassa-report'),
                },
                {
                    path: '/finance/transfer-orders',
                    title: 'Передача заказов',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('buh:view-transfer-orders'),
                },
                {
                    path: '/finance/recalculate-orders',
                    title: 'Пересчёт заказов',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('buh:recalculate-orders'),
                },
            ],
        },
        {
            title: 'Клиентский отдел',
            path: '/client-service/phone-extension',
            icon: 'icon-client-service',
            urlDiversion: false,
            items: [
                {
                    path: '/client-service/phone-extension',
                    title: 'Настройка номера',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('mango:change-extension'),
                },
                {
                    path: '/client-service/news',
                    title: 'Новости',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('news:view'),
                },
                {
                    path: '/client-service/clients-sends',
                    title: 'Рассылка клиентам',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('clients-sends:view'),
                },
                {
                    path: '/client-service/sms-sender',
                    title: 'Рассылка СМС',
                    urlDiversion: false,
                    view: (user: User) =>   user.inPermission('service:mass-sms')
                }
            ]
        },
        {
            title: 'Курьеры',
            path: '/couriers/map',
            icon: 'fa fa-truck',
            urlDiversion: false,
            items: [
                {
                    path: '/couriers/map',
                    title: 'Карта',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('couriers:map'),
                },
                {
                    path: '/couriers/route-list',
                    title: 'Маршрутный лист',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('couriers:route-list'),
                },
                {
                    path: '/couriers/claim-letter',
                    title: 'Претензионное письмо',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('couriers:map'),
                },
            ],
        },
        // {
        //     title: 'Отчёты',
        //     path: '/reports',
        //     icon: 'fa fa-list-alt',
        //     urlDiversion: false,
        //     items: [
        //         {
        //             path: '/reports/detmir-remains',
        //             title: 'Детмир отчёт по остаткам',
        //             urlDiversion: false,
        //             view: (user: User) => (user.inPermission('report:detmir-remains')),
        //         },
        //     ],
        // },
        {
            title: 'Адреса',
            path: '/admin/addresses',
            icon: 'fa fa-map-marked-alt',
            urlDiversion: false,
            items: [
                {
                    path: '/admin/addresses/cache-reattachment',
                    title: 'Переподчинение кэша',
                    urlDiversion: false,
                    view: (user: User) => (
                        user.inPermission('addresses:cache:view') || user.inPermission('addresses:cache:reattach')
                    ),
                },
            ],
        },
    ];

    constructor(
        private currentUser: CurrentUserService,
        private router: Router,
        protected api: LaraService,
        private eventerService: EventerService,
        private title: Title,
    ) {
    }

    is = (fileName: string, ext: string) => new RegExp(`.${ext}\$`).test(fileName);

    ngOnInit() {
        this.loading = true;
        this.currentUser.get().subscribe((user: User) => {
            this.user = new User(user);
            if (!user) {
                this.menu = [];
            } else if (user.isRoleAdmin()) {
                this.menu = this.menuSource;
            } else {
                const filter = item => item.view(user);
                this.menu = this.menuSource.filter(item => {
                    if (item.items) {
                        item.items = item.items.filter(filter);
                    }
                    item = this.checkingPermissionToAccessMenuSection(item);
                    return filter(item);
                });
            }

            this.setTitleFromMenu();
            this.setActiveParent();
        });

        this.routerSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.activeRootItem = null;
                this.activeItem = null;
                this.activatedParentItems = [];

                this.setTitleFromMenu();
                this.setActiveParent();
            }
        })

        this.sidebarToggleSubscription = this.eventerService.adminSidebarToggle.subscribe(opened => {
            this.sidebarIsOpened = opened;

            if (window.innerWidth > 767 && opened) {
                this.sidebarIsMinified = true;
                this.activatedParentItems = [];
            } else {
                this.sidebarIsMinified = false;
            }
        })

        this.loading = false;
    }

    ngOnDestroy() {
        this.routerSubscription.unsubscribe();
        this.sidebarToggleSubscription.unsubscribe();
    }

    public onParentClick(itemIndex) {
        const findedItemIndex = this.activatedParentItems.indexOf(itemIndex);
        if (findedItemIndex > -1) {
            this.activatedParentItems.splice(findedItemIndex, 1);
        } else {
            if (this.sidebarIsMinified) {
                this.activatedParentItems = [];
            }
            this.activatedParentItems.push(itemIndex);
        }
    }

    public parentIsActive(parentIndex) {
        return this.activatedParentItems.indexOf(parentIndex) > -1;
    }

    public iconClass({text, items}: any): any {
        return {
            'k-i-file-pdf': this.is(text, 'pdf'),
            'k-i-folder': items !== undefined,
            'k-i-html': this.is(text, 'html'),
            'k-i-image': this.is(text, 'jpg|png'),
            'k-icon': true,
        };
    }

    isActiveItem(item) {
        if (this.activeItem) {
            return item.path === this.activeItem.path;
        }

        if (this.router.url === item.path) {
            this.activeItem = item;
            return true;
        } else {
            if (this.urlHasPathWithParams(item.path)) {
                this.activeItem = item;
                return true;
            }

            return false;
        }
    }

    setActiveParent() {
        if (this.sidebarIsMinified) {
            this.activatedParentItems = [];
            return;
        }

        this.menu.forEach((item) => {
            if (this.getActiveSubItem(item)) {
                this.activatedParentItems.push(item.path);
            }
        });
    }

    /**
     * Рекурсивно проверяем дочерние элементы
     * @param item
     */
    getActiveSubItem(item) {
        if (this.isActiveItem(item)) {
            return item;
        }

        if (!item.items || item.items.length === 0) {
            return null;
        }

        let activeChild;
        item.items.forEach((submenu) => {
            const activeChildSubitem = this.getActiveSubItem(submenu);
            if (activeChildSubitem) {
                activeChild = activeChildSubitem;
                return;
            }
        });

        if (activeChild) {
            return activeChild;
        }

        return null;
    }

    /**
     * Проверяем, не содержит ли url путь текущего элемента с параметром /id
     * @param path
     */
    private urlHasPathWithParams(path) {
        if (this.router.url.includes(path)) {
            return path === this.router.url.replace(/\/\d+$/, '');
        }

        return false;
    }

    /**
     * Установить Title по активному пункту меню
     */
    private setTitleFromMenu() {
        this.menu.forEach((item) => {
            const activeSubItem = this.getActiveSubItem(item);
            if (activeSubItem && activeSubItem.title) {
                this.title.setTitle(activeSubItem.title);
            }
        });
    }

    /**
     * Проверка, есть ли у пользователя доступ к разделу меню.
     * Если есть доступ хотя бы к 1 подпункту меню, открываем доступ к разделу.
     * @private
     */
    private checkingPermissionToAccessMenuSection(item: RouteInfo): RouteInfo {
        item.view = (user: User) => (item.items.length >= 1)
        return item;
    }
}
